<template>
  <b-sidebar
    id="add-new-row-sidebar"
    :visible="isAddNewRowSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-row-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Row
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Clasification -->
          <validation-provider
            #default="validationContext"
            name="Clasificacion"
            rules="required"
          >
            <b-form-group
              label="Clasificacion"
              label-for="clasificacion"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newRowData.clasificacion"
                :options="clasificationOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="clasificacion"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- nart -->
          <validation-provider
            #default="validationContext"
            name="Nart"
            rules="required"
          >
            <b-form-group
              label="Nart"
              label-for="nart"
            >
              <b-form-input
                id="nart"
                v-model="newRowData.nart"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- descripcion -->
          <!-- <validation-provider
            #default="validationContext"
            name="Descripcion"
            rules="required"
          >
            <b-form-group
              label="Descripcion"
              label-for="descripcion"
            >
              <b-form-input
                id="descripcion"
                v-model="newRowData.descripcion"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Aplication Form -->
          <validation-provider
            v-if="isShopperOrPromoEnabled"
            #default="validationContext"
            name="Aplication Form"
            rules="required"
          >
            <b-form-group
              label="Aplication Form"
              label-for="aplication-form"
            >
              <b-form-input
                id="aplication-form"
                v-model="newRowData.application_form"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Canal -->
          <validation-provider
            v-if="isCanalEnabled"
            #default="validationContext"
            name="Canal"
            rules="required"
          >
            <b-form-group
              label="Canal"
              label-for="canal"
            >
              <b-form-input
                id="canal"
                v-model="newRowData.canal"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <!-- Tipo Promo -->
          <validation-provider
            v-if="isShopperOrPromoEnabled"
            #default="validationContext"
            name="Tipo Promo"
            rules="required"
          >
            <b-form-group
              label="Tipo Promo"
              label-for="tipo_promo"
            >
              <b-form-input
                id="tipo_promo"
                v-model="newRowData.tipo_promo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Year -->
          <validation-provider
            #default="validationContext"
            name="Año"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Año"
              label-for="year"
            >
              <b-form-input
                id="year"
                v-model="newRowData.year"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Month -->
          <validation-provider
            #default="validationContext"
            name="Mes"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Mes"
              label-for="month"
            >
              <b-form-input
                id="month"
                v-model="newRowData.month"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Cantidad -->
          <validation-provider
            #default="validationContext"
            name="Cantidad"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Cantidad"
              label-for="cantidad"
            >
              <b-form-input
                id="cantidad"
                v-model="newRowData.cantidad"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRowSidebarActive',
    event: 'update:is-add-new-row-sidebar-active',
  },
  props: {
    isAddNewRowSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {

    const vm = getCurrentInstance().proxy

    const clasificationOptions = [
      { label: 'Baseline', value: 'BASELINE' },
      { label: 'Launch', value: 'LAUNCH' },
      { label: 'Promo', value: 'PROMO' },
      { label: 'Shopper', value: 'SHOPPER' },
    ]

    const blankRowData = {
      clasificacion: 'BASELINE',
      nart: '',
      descripcion: '',
      application_form: '',
      canal: '',
      tipo_promo: '',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      cantidad: 0,
    }

    const newRowData = ref(JSON.parse(JSON.stringify(blankRowData)))
    const resetuserData = () => {
      newRowData.value = JSON.parse(JSON.stringify(blankRowData))
    }

    const isShopperOrPromoEnabled = computed(() => {
      return newRowData.value.clasificacion == 'SHOPPER' || newRowData.value.clasificacion == 'PROMO'
    })

    const isCanalEnabled = computed(() => {
      return newRowData.value.clasificacion != 'BASELINE'
    })

    const onSubmit = () => {
      // console.log(newRowData)
      axios
      .post(`${process.env.VUE_APP_BASE_URL}/new_row`, 
        { data: newRowData.value }
      )
      .then(() => {
        vm.$swal({
          title: 'Row agregado!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            emit('refetch-data')
            emit('update:is-add-new-row-sidebar-active', false)
          }
        }) 
      })
      .catch(error => {
        console.log(error.response)
        vm.$swal({
          title: 'Error al agregar row!',
          text: `Error: ${error.response.data.error}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      newRowData,
      onSubmit,
      clasificationOptions,

      isShopperOrPromoEnabled,
      isCanalEnabled,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#add-new-row-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
